
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline; /* hover 시에 밑줄 추가 */
}
/* a:visited {
  color: inherit;
} */
body {
  overflow-y: scroll;
}
